import { NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColorUtil } from '@core/utils';
import { User } from '@core/entities/user';

@Component({
  standalone: true,
  selector: 'kt-generic-avatar',
  templateUrl: './generic-avatar.component.html',
  styleUrls: ['./generic-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgStyle,
  ],
})
export class GenericAvatarComponent {
  static readonly COLOR_WHITE = '#FFF';
  static readonly COLOR_BLACK = '#000';

  protected data: {
    color: string;
    textColor: string;
    title: string;
    avatar: string;
  } | undefined = undefined;

  @Input({ required: true }) set user(data: Pick<User, 'avatar' | 'name'>) {
    const color = ColorUtil.generateHexColor(data.name);

    this.data = {
      color,
      textColor: this.generateTitleColor(color),
      title: this.generateTitle(data.name),
      avatar: data.avatar || ''
    };
  };

  private generateTitleColor(color: string): string {
    return ColorUtil.getColorLuma(color) < 128 ? GenericAvatarComponent.COLOR_WHITE : GenericAvatarComponent.COLOR_BLACK;
  }

  private generateTitle(text: string): string {
    const normalizedName = (text.toLocaleUpperCase().replace(/[^\w\s]/gi, '') || '');

    if (normalizedName.length <= 2) {
      return normalizedName;
    }
    const dataParts = normalizedName.split(/\s/)

    return `${dataParts[0].charAt(0)}${dataParts[dataParts.length - 1].charAt(0)}`;
  }
}
