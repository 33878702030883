<div class="navigation-wrapper">
  <div class="keen-slider"
       #ref>
    <ng-container *ngFor="let url of data; let i = index">
      <div class="keen-slider__slide">
        <img [src]="url"
             (click)="selectImage.emit(i)" />
      </div>
    </ng-container>

  </div>

  <ng-container *ngIf="data?.length">
    <kt-generic-button appearance="icon"
                       class="arrow arrow--left"
                       [disabled]="currentSlide === 0"
                       (click)="slider?.prev()">navigate_before</kt-generic-button>
    <kt-generic-button appearance="icon"
                       class="arrow arrow--right"
                       [disabled]="data?.length === 0 || currentSlide === (data?.length || 0) - 1"
                       (click)="slider?.next()">navigate_next</kt-generic-button>
  </ng-container>
</div>