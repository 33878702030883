<ng-container *ngIf="!hidden">
  <div (click)="proxyClick($event)"
       [style.display]="'inline-block'"
       [ngStyle]="{ padding: padding + 'px', width: btnAppearance === 'mat-menu-item' ? '100%' : 'auto' }">
    <ng-container [ngSwitch]="btnAppearance">

      <span [matTooltip]="tooltipDisabledMessage"
            [matTooltipPosition]="tooltipPosition"
            [matTooltipDisabled]="!disabled">

        <ng-container *ngSwitchCase="'mat-menu-item'">
          <button mat-menu-item
                  [matTooltipPosition]="tooltipPosition"
                  [matTooltip]="disabled && tooltipDisabledMessage ? '' : tooltipMessage || matTooltip"
                  [ngStyle]="{'background-color': customColor, 'border-color': customColor }"
                  [disabled]="disabled"
                  (click)="click()">
            <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="'flat'">
          <button mat-flat-button
                  [matTooltipPosition]="tooltipPosition"
                  [matTooltip]="disabled && tooltipDisabledMessage ? '' : tooltipMessage || matTooltip"
                  [color]="themeColor"
                  [ngStyle]="{'background-color': customColor, 'border-color': customColor }"
                  [disabled]="disabled"
                  (click)="click()">
            <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="'raised'">
          <button mat-raised-button
                  [matTooltipPosition]="tooltipPosition"
                  [matTooltip]="disabled && tooltipDisabledMessage ? '' : tooltipMessage || matTooltip"
                  [color]="themeColor"
                  [ngStyle]="{'background-color': customColor, 'border-color': customColor }"
                  [disabled]="disabled"
                  (click)="click()">
            <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="'basic'">
          <button mat-button
                  [matTooltipPosition]="tooltipPosition"
                  [matTooltip]="disabled && tooltipDisabledMessage ? '' : tooltipMessage || matTooltip"
                  [color]="themeColor"
                  [ngStyle]="{'color': customColor, 'border-color': customColor }"
                  [disabled]="disabled"
                  (click)="click()">
            <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="'stroked'">
          <button mat-stroked-button
                  [matTooltipPosition]="tooltipPosition"
                  [matTooltip]="disabled && tooltipDisabledMessage ? '' : tooltipMessage || matTooltip"
                  [color]="themeColor"
                  [ngStyle]="{'color': customColor, 'border-color': customColor }"
                  [disabled]="disabled"
                  (click)="click()">
            <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="'fab'">
          <button mat-fab
                  [matTooltipPosition]="tooltipPosition"
                  [matTooltip]="disabled && tooltipDisabledMessage ? '' : tooltipMessage || matTooltip"
                  [color]="themeColor"
                  [disabled]="disabled"
                  (click)="click()">
            <mat-icon>
              <ng-container [ngTemplateOutlet]="content"></ng-container>
            </mat-icon>
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="'fab-extended'">
          <button mat-fab
                  extended
                  [matTooltipPosition]="tooltipPosition"
                  [matTooltip]="disabled && tooltipDisabledMessage ? '' : tooltipMessage || matTooltip"
                  [color]="themeColor"
                  [disabled]="disabled"
                  (click)="click()">
            <mat-icon>
              <ng-container [ngTemplateOutlet]="contentIcon"></ng-container>
            </mat-icon>
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="'mini-fab'">
          <button mat-mini-fab
                  [matTooltipPosition]="tooltipPosition"
                  [matTooltip]="disabled && tooltipDisabledMessage ? '' : tooltipMessage || matTooltip"
                  [color]="themeColor"
                  [disabled]="disabled"
                  (click)="click()">
            <mat-icon>
              <ng-container [ngTemplateOutlet]="content"></ng-container>
            </mat-icon>
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="'icon'">
          <button mat-icon-button
                  [matTooltipPosition]="tooltipPosition"
                  [matTooltip]="disabled && tooltipDisabledMessage ? '' : tooltipMessage || matTooltip"
                  [color]="themeColor"
                  [ngStyle]="{'color': customColor }"
                  [disabled]="disabled"
                  (click)="click()">
            <mat-icon [ngStyle]="{ 'transform': 'scale(' + scale + ')' }">
              <ng-container [ngTemplateOutlet]="content"></ng-container>
            </mat-icon>
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="'link'">
          <button mat-button
                  [matTooltipPosition]="tooltipPosition"
                  [matTooltip]="disabled && tooltipDisabledMessage ? '' : tooltipMessage || matTooltip"
                  [ngStyle]="{'color': disabled ? '#000' : LINK_COLOR }"
                  [style.font-weight]="400"
                  [style.min-width.px]="0"
                  [disabled]="disabled"
                  (click)="click()">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </button>
        </ng-container>

      </span>

    </ng-container>
  </div>
</ng-container>

<!-- Used with fab-extended button. Should contain icon with attribute 'icon' to be projected as icon part of button -->
<ng-template #contentIcon>
  <ng-content select="[icon]"></ng-content>
</ng-template>

<ng-template #content>
  <ng-container *ngIf="!loading; else loadingTpl">
    <ng-content></ng-content>
  </ng-container>
</ng-template>

<ng-template #loadingTpl>
  <div class="loading rotate">
    <mat-icon>sync</mat-icon>
  </div>
</ng-template>

<ng-template #confirmationDialogTpl>
  <h1 mat-dialog-title>{{ confirmationMessage }}</h1>
  <div mat-dialog-content>{{ confirmationDescription }}</div>
  <div mat-dialog-actions
       class="dialog-actions">
    <button mat-flat-button
            mat-dialog-close>Cancel</button>
    <button mat-flat-button
            [color]="confirmationType === 'warning' ? 'warn' : 'primary'"
            [mat-dialog-close]="true"
            cdkFocusInitial>Confirm</button>
  </div>
</ng-template>