import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeviceService } from '@core/services';

@Directive({
  standalone: true,
  selector: '[ifDevice]'
})
export class IfDeviceDirective implements OnDestroy {
  private templateRef = inject(TemplateRef)
  private viewContainer = inject(ViewContainerRef);
  private cdRef = inject(ChangeDetectorRef);
  private deviceService = inject(DeviceService);

  private rendered = false;
  private devices$ = new Subject<string[]>();

  private readonly destroyed = new Subject<void>();

  @Input() set ifDevice(device: string | string[]) {
    this.devices$.next(Array.isArray(device) ? device : [device]);
  }

  constructor() {
    combineLatest([
      this.deviceService.device$,
      this.devices$
    ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(([currentDevice, allowedDevices]) => {
        if (allowedDevices.includes(currentDevice)) {
          this.show()
        } else {
          this.hide();
        }
      });
  }

  private show(): void {
    if (!this.rendered) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.cdRef.markForCheck();
      this.rendered = true;
    }
  }

  private hide(): void {
    if (this.rendered) {
      this.viewContainer.clear();
      this.rendered = false;
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
