import {
  Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[ktLoading]'
})
export class LoadingDirective implements OnInit, OnChanges {

  @Input('ktLoading') loading = false;
  elText?: string;
  spinner!: ElementRef;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.elText = this.el.nativeElement.innerText;

    this.spinner = this.renderer.createElement('span');
    this.renderer.addClass(this.spinner, 'spinner-border');
    this.renderer.addClass(this.spinner, 'spinner-border-sm');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.spinner) {
      if (changes['loading'].currentValue) {
        this.el.nativeElement.innerText = '';
        this.renderer.appendChild(this.el.nativeElement, this.spinner);
      } else {
        this.renderer.removeChild(this.el.nativeElement, this.spinner);
        this.el.nativeElement.innerText = this.elText;
      }
      this.el.nativeElement.disabled = this.loading;
    }
  }

}
