// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Subject } from 'rxjs';
import {map} from 'rxjs/operators';
// NGRX
// Auth reducers and selectors
import { AuthService } from '../_services/auth.service';
import { ApiResponse } from '@coremodels';

@Injectable()
export class AuthInviteGuard  {
  private unsubscribe: Subject<any>;

  constructor(private router: Router, private auth: AuthService) {
    this.unsubscribe = new Subject();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.isInvited(route.params['token'])
      .pipe(
        map(
          (response: ApiResponse<any>) => {
            if (response == null || (response && !response.success)) {
              this.router.navigateByUrl('/login');
              return false;
            }
            return true;
          }),
      );
  }
}
