<ng-container *ngIf="imageUrl || imagePreviewUrl">
  <div
    class="image"
    [style.height]="height || '100%'"
    [style.width]="width || '100%'"
    (mouseenter)="handleMouseEnterEvent($event)"
    (mouseleave)="handleMouseLeaveEvent($event)"
  >
    <img
      class="image__img"
      height="100%"
      width="100%"
      [src]="(imagePreviewUrl || imageUrl) | safe:'resourceUrl'"
      [alt]="imagePreviewUrl || imageUrl"
    >

    <div
      class="image__info"
      *ngIf="showInfo"
    >
      <kt-generic-button
        appearance="icon"
        tooltipMessage="Copy to clipboard"
        (click)="copyUrlToClipboard(imageUrl)"
      >link</kt-generic-button>
    </div>
  </div>
</ng-container>
