export const downloadImage = async (url: string, name: string = 'image'): Promise<void> => {
  const filename = `${name}.${url.slice(url.lastIndexOf('.') + 1, url.length) || 'jpg'}`
  const link = document.createElement('a');
  link.setAttribute('download', filename);
  document.documentElement.append(link);

  const response = await fetch(url);
  const objectURL = URL.createObjectURL(await response.blob());

  link.href = objectURL;
  link.click();
  link.remove();
}

export const openImageInNewWindow = (url: string): void => {
  window.open(url, '_blank');
}
