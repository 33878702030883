import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppRouteError } from '@core/entities/routing';

@Injectable({ providedIn: 'root' })
export class NavigationService<T = unknown> {
  private route = inject(Router);

  navigateTo(path: T, ...entityIds: string[]): void {
    const commands = this.buildNavigationSource(path, ...entityIds);
    this.route.navigate(commands);
  }

  buildNavigationSource(entity: T, ...entityIds: string[]): string[] {
    const hasParams = Boolean((entity as string).match(/:\w+/g)?.length);

    if (hasParams) {
      let matchCounter = 0;
      return [(entity as string).replaceAll(/:\w+/g, match => {
        if (entityIds.length < matchCounter + 1) {
          throw new AppRouteError(`Build navigation URL error: entity id for ${match} parameter is missing`);
        }

        return entityIds[matchCounter++];
      })] as string[];
    }

    return [entity] as string[];
  }
}
