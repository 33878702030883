import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiResponse } from '@core/models';
import { ApiUtilsService } from './api-utils.service';

interface GetOptions {
  fields: string[];
  responseType: 'json';
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private httpUtils: ApiUtilsService,
  ) { }

  get<T>(
    path: string,
    httpParams: any = {},
    options: Partial<GetOptions> = {},
  ): Observable<ApiResponse<T>> {
    const params = this.httpUtils.convertObjectToHttpParams(httpParams);
    let headers = new HttpHeaders();
    if (Array.isArray(options?.fields)) {
      headers = headers.append('fields', options.fields);
    }
    const getOptions = {
      params,
      headers,
      responseType: 'json' as 'json',
    };

    return this.http.get<ApiResponse<T>>(path, getOptions);
  }

  put<T>(path: string, body: any): Observable<ApiResponse<T>> {
    return this.http.put<ApiResponse<T>>(path, body);
  }

  post<T>(path: string, body: any): Observable<ApiResponse<T>> {
    return this.http.post<ApiResponse<T>>(path, body);
  }

  delete<T>(path: string): Observable<ApiResponse<T>> {
    return this.http.delete<ApiResponse<T>>(path);
  }
}

