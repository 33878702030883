export interface Asset {
  key: string;
  uploadUrl: string;
  previewUrl: string;
  url: string;
}

export enum AssetType {
  inventory = 'inventory',
  clients = 'clients',
  users = 'users',
  productType = 'product-type',
  productOption = 'product-option',
  qualityCheck = 'quality-check',
}

export enum ImageFileExtension {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  GIF = 'gif',
  BMP = 'bmp',
  TIFF = 'tiff',
  PSD = 'psd',
  APNG = 'apng',
  AVIF = 'avif',
  WEBP = 'webp',
  ICO = 'ico',
  XBM = 'xbm',
}

export enum DocumentFileExtension {
  SVG = 'svg',
  DOC = 'doc',
  DOCX = 'docx',
  PDF = 'pdf',
  TXT = 'txt',
  RTF = 'rtf',
  CSV = 'csv',
  XLS = 'xls',
  XLSX = 'xlsx',
  PPT = 'ppt',
  PPTX = 'pptx'
}

export type FileExtension = ImageFileExtension | DocumentFileExtension;

export const FileExtensions: { [key: string]: FileExtension } = {
  ...ImageFileExtension,
  ...DocumentFileExtension,
};