import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiResponse, CreateOrderCloneDto, CustomerAddress, Order, OrderCancelReason, OrderPackingDimensions } from '@core/models';
import { ApiService, CrudService } from './api';
import { OrderItemReport } from '@coremodels/order/order-report.model';
import { OrderProductionState } from '@coremodels/order/order-production.model';
import { createRequestVirtualUserContext } from '@coreauth/interceptors';
import { OrderTag } from '@core/entities/order-tag';

const endpoint = '/orders';
@Injectable({
  providedIn: 'root'
})
export class OrderService extends CrudService<Order> {
  static readonly endpoint = '/orders';
  get endpoint(): string {
    return endpoint;
  }
  constructor(
    protected override http$: HttpClient,
    protected override apiService: ApiService,
  ) {
    super(apiService, http$);
  }

  getShippingCodes(): Observable<string[]> {
    const url = `/orders/shipping-codes`;
    return this.http$.get<string[]>(url);
  }

  loadOrderProductionState(orderNumber: string): Observable<OrderProductionState[]> {
    const url = `/orders/production-state`;
    const params = new HttpParams().append('orderNumber', encodeURIComponent(orderNumber));
    return this.http$.get<OrderProductionState[]>(url, { params });
  }

  cancelOrder(orderId: string, data: { reason: OrderCancelReason, details?: string }): Observable<Order> {
    const url = `/orders/${orderId}/cancel`;
    return this.http$.post<Order>(url, data, { context: createRequestVirtualUserContext() });
  }

  shipOrder(orderId: string): Observable<Order> {
    const url = `/orders/${orderId}/ship`;
    return this.http$.post<Order>(url, {}, { context: createRequestVirtualUserContext() });
  }

  setPackingInfo(orderId: string, dimensions: OrderPackingDimensions, clientId: string): Observable<OrderPackingDimensions> {
    const url = `/orders/${orderId}/packing`;
    const params = new HttpParams().set('clientId', clientId);
    return this.http$.put<OrderPackingDimensions>(url, dimensions, { params, context: createRequestVirtualUserContext() });
  }

  getOrderItemSummary(filter: { batchName?: string, id?: string; } = {}): Observable<OrderItemReport[]> {
    const url = `/orders/item-report`;
    const params = new HttpParams({ fromObject: filter });
    return this.http$.get<OrderItemReport[]>(url, { params });
  }

  getOrderFramePrintSummary(filter: { [key: string]: any }): Observable<ApiResponse<any>> {
    const url = `/orders/frames-summary`;
    const params = new HttpParams({ fromObject: filter });
    return this.http$.get<ApiResponse<any[]>>(url, { params });

  }

  getOrderItems(orderId: string): Observable<ApiResponse<any>> {
    const url = `/orders/${orderId}/items`;
    return this.http$.get<ApiResponse<any[]>>(url);
  }

  getTags(): Observable<string[]> {
    return this.http$.get<string[]>(`${OrderService.endpoint}/tags`);
  }

  copyOrder(orderId: string, data: CreateOrderCloneDto): Observable<Order> {
    const url = `/orders/${orderId}/copy`;
    return this.http$.post<Order>(url, data);
  }

  updateShippingAddress(orderId: string, data: CustomerAddress): Observable<ApiResponse<CustomerAddress>> {
    const url = `/orders/${orderId}/shipping-address`;
    return this.http$.put<ApiResponse<CustomerAddress>>(url, data);
  }

  importExternalOrder(clientId: string, externalOrderId: string, channel?: string): Observable<unknown> {
    const url = '/orders/external-import';
    return this.http$.post<unknown>(url, { clientId, externalOrderId, channel });
  }

  addTag(orderId: string, data: { name: string; description?: string; }): Observable<OrderTag> {
    return this.http$.post<OrderTag>(`/orders/${orderId}/tags`, data);
  }

  removeTag(orderId: string, tagName: string): Observable<OrderTag> {
    return this.http$.delete<OrderTag>(`/orders/${orderId}/tags/${tagName}`);
  }
}
