<ng-container *ngIf="data">
  <div class="avatar"
       [ngStyle]="{
        'background-image': 'url(' + data.avatar + ')',
        'background-color': data.color,
        'color': data.textColor,
        'font-size': '2rem',
        'font-weight': '600',
       }">
    <ng-container *ngIf="!data.avatar">{{ data.title }}</ng-container>
  </div>
</ng-container>