export enum AttachmentType {
  image = 'image',
  document = 'document',
  unknown = 'unknown',
}

export interface AttachmentData {
  url: string;
  title: string;
  fileName: string;
  type: AttachmentType;
  removable: boolean;
  downloadable: boolean;
  previewable: boolean;
  imgIdx?: number;
}