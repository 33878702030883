export interface Report<T = any> {
  name: string;
  data: T
}

export enum ReportName {
  TotalOrdersSubmittedPerDay = 'total_orders_submitted_per_day',
  TotalOrdersSubmittedPerDayRanges = 'total_orders_submitted_per_day_ranges',
  TotalOrdersShippedPerDayRanges = 'total_orders_shipped_per_day_ranges',
  TotalOrdersBatchedPerDayRanges = 'total_orders_batched_per_day_ranges',
  TotalOrdersShippedPerDay = 'total_orders_shipped_per_day',
  TotalOrdersQueuedPerDay = 'total_orders_queued_per_day',
  TotalItemsPerStage = 'TOTAL_ITEMS_PER_STAGE',
  TotalOrdersPerStatusWithSLA = 'TOTAL_ORDERS_PER_STATUS_WITH_SLA',
  TotalProductTypesPerOrderStatus = 'TOTAL_PRODUCT_TYPES_PER_ORDER_STATUS',
}

interface ReportItem {
  label: string;
  value: number;
}

export interface ReportResponse {
  metric: string;
  title: string;
  data: ReportItem[];
}
