import { parse } from 'papaparse';

export class CsvUtil {
  static parseCsv(file: File): Promise<string[][]> {
    return new Promise((resolve, reject) => {
      parse(file, {
        skipEmptyLines: true,
        complete: results => resolve(results.data as string[][]),
      })
    })
  }

  static async extractData<T>(file: File, fields: string[]): Promise<T[]> {

    const data = await CsvUtil.parseCsv(file);
    const titles = data[0].map(title => title?.trim().toLocaleLowerCase());
    const itemsData = data.slice(1);

    const fieldMap = fields.reduce((map, field) => ({
      ...map,
      [field]: titles.indexOf(field),
    }), {} as Record<string, number>);

    return itemsData.filter(line => line.some(Boolean)).reduce((items, line) => {
      const item = Object.entries(fieldMap).reduce((item, [fieldName, fieldIndex]) => ({
        ...item,
        ...(line[fieldIndex] && { [fieldName]: line[fieldIndex] })
      }), {} as T);

      return [...items, item];
    }, [] as T[]);

  }
}
