import {
  FactoryItem,
  Flyer,
  Item,
  ItemProductionState,
  ProductionStage,
} from '@core/models';
import { ProductType } from '@core/entities/product-type';

export enum ScanningStationProductionType {
  canvas = 'canvas',
}

export interface ScanningStation {
  _id: string;
  name: string;
  productionStage: ProductionStage;
  productTypes: ProductType[];
  disabled?: boolean;
  selected?: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ScanningStationFilter extends Omit<ScanningStation, 'productionStage'> {
  productionStage: string;
}

export interface Panel {
  SKU: string;
  name: string;
  Location: string;
  ImageUrl: string;
  OrderId: number;
  OrderNumber: string;
  PanelId: string;
  ParentItemId: string;
  Part: number;
  ProductionState?: ItemProductionState[];
}

export interface ScanningResult {
  Client: string;
  FactoryItems: FactoryItem[];
  id: string;
  Items: Item[];
  OrderID: number;
  OrderNumber: string;
  OrderStatus: string;
  Flyers?: Flyer[];
}

export interface OrderProductionState {
  Client: string;
  FactoryItems: FactoryItem[];
  id: string;
  Items: any[];
  OrderNumber: string;
  OrderStatus: string;
  PackingDimensions: any;
  ReadyForPacking: boolean;
}
