import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpContextToken, HttpContext, } from '@angular/common/http';

import { Store, select } from '@ngrx/store';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '@environment';
import { AppState } from '@core/reducers';
import { GlobalPreloaderService, NotificationService } from '@core/services/ui';
import { QueryUserDto } from '@core/entities/user';
import { isWorkstation } from '@coreauth/_selectors/auth.selectors';
import { VirtualUserService } from '@features/virtual-user';

export const EXTERNAL_HTTP_REQUEST = new HttpContextToken<boolean>(() => false);
export const TIMEZONE_REQUIRED = new HttpContextToken<boolean>(() => false);
export const SKIP_ERROR_HANDLER = new HttpContextToken<boolean>(() => false);
export const REQUEST_VIRTUAL_USER = new HttpContextToken<QueryUserDto | null>(() => null);

export const createRequestVirtualUserContext = (query: QueryUserDto = {}) => new HttpContext().set(REQUEST_VIRTUAL_USER, query);

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  static EMPLOYEE_ID_HEADER = 'x-virtual-user-id'; // virtual user id

  private store = inject<Store<AppState>>(Store)
  private virtualUserService = inject(VirtualUserService);
  private notification = inject(NotificationService);
  private globalPreloader = inject(GlobalPreloaderService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.globalPreloader.show();
    return of(request).pipe(
      withLatestFrom(this.store.pipe(select(isWorkstation))),
      switchMap(([request, workstation]) => this.handleHttpContext(request, workstation)),
      switchMap(r => next.handle(r)),
      catchError((error: HttpErrorResponse) => {
        this.globalPreloader.hide();
        return this.handleErrorResponse(error, request);
      }),
      finalize(() => this.globalPreloader.hide()),
    );
  }

  private handleHttpContext(request: HttpRequest<any>, workstation: boolean): Observable<HttpRequest<any>> {
    return of(request).pipe(
      map(req => this.handleTimezoneContext(req)),
      map(req => this.handleApiEndpoint(req)),
      switchMap(req => workstation ? this.handleEmployeeContext(req) : of(req)),
      catchError(error => {

        if (error.message.includes('Virtual user selection was rejected')) {
          return EMPTY;
        }

        this.notification.showError(error.message);
        return EMPTY;
      }),
    );
  }

  private handleApiEndpoint(request: HttpRequest<any>): HttpRequest<any> {
    if (!request.context.get(EXTERNAL_HTTP_REQUEST)) {
      return request.clone({
        url: `${environment.serverUrl}${request.url}`,
      });
    }

    return request;
  }

  private handleTimezoneContext(request: HttpRequest<any>): HttpRequest<any> {
    if (request.context.get(TIMEZONE_REQUIRED) === true) {
      return request.clone({
        params: request.params.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone),
      });
    }

    return request;
  }

  private handleEmployeeContext(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    const userQuery = request.context.get(REQUEST_VIRTUAL_USER);

    if (userQuery) {
      return this.virtualUserService.getUser(userQuery).pipe(
        map(data => {
          if (data.status === 'rejected') {
            throw new Error('Virtual user selection was rejected')
          }

          if (!data.user) {
            throw new Error('User is required')
          }

          return request.clone({
            headers: request.headers.append(ApiInterceptor.EMPLOYEE_ID_HEADER, data.user)
          })
        })
      )
    }

    return of(request);
  }

  private handleErrorResponse(error: HttpErrorResponse, request: HttpRequest<any>): Observable<never> {

    if(!request.context.get(SKIP_ERROR_HANDLER)) {
      this.notification.showError(error.error ? error.error.message : error.message);
    }
    
    console.warn(error.message);
    return throwError(() => error);
  }
}
