import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, } from 'rxjs';
import { map } from 'rxjs/operators';

import { createRequestVirtualUserContext } from '@core/auth/interceptors';
import { ApiResponse, extractApiResponseData, ItemPreview, ItemProductionTrack, } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  static readonly endpoint = 'items';
  private http$ = inject(HttpClient);

  getItemPreview(itemId: string): Observable<ItemPreview> {
    const url = `/${ItemService.endpoint}/${itemId}/preview`;
    return this.http$.get<ApiResponse<ItemPreview>>(url).pipe(
      map(extractApiResponseData),
    );
  }

  createItemProductionTrack(
    itemId: string,
    itemIndex: number,
    stageId: string,
    stationId: string,
  ): Observable<ItemProductionTrack> {
    const url = `/${ItemService.endpoint}/${itemId}/track`;
    const payload = { itemIndex, stationId };
    return this.http$.post<ItemProductionTrack>(url, payload, { context: createRequestVirtualUserContext({ stageId }) });
  }
}
