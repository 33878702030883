<div>
  <div class="tooltip__conatiner">
    <ng-template #textTemplate>
      <div class="tooltip__text" *ngIf="message">{{ message }}</div>
      <ng-container *ngIf="messages">
        <div class="tooltip__text" *ngFor="let message of messages">
          • {{ message }}
        </div>
      </ng-container>
    </ng-template>
    <ng-container *ngTemplateOutlet="template || textTemplate">
    </ng-container>
  </div>
</div>
