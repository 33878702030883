import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableComponent } from './ui/table/table.component';
import { TableHeaderComponent } from './ui/table-header/table-header.component';
import { TableRowComponent } from './ui/table-row/table-row.component';
import { TableColumnComponent } from './ui/table-column/table-column.component';
import { MatDividerModule } from '@angular/material/divider';

const COMPONENTS = [
  TableComponent,
  TableHeaderComponent,
  TableRowComponent,
  TableColumnComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    MatDividerModule,
  ],
  exports: COMPONENTS,
})
export class TableModule { }
