import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map, } from 'rxjs/operators';
import { DateUtil } from '@core/utils';

@Pipe({
  standalone: true,
  name: 'durationAsync'
})
export class DurationAsyncPipe implements PipeTransform {
  static readonly EMIT_INTERVAL = 1000 * 60; // each minute

  transform(startDate: string, endDate?: string): Observable<string> {

    if (startDate && endDate) {
      return of(DateUtil.formatDistance(new Date(startDate), new Date(endDate)));
    }

    return timer(0, DurationAsyncPipe.EMIT_INTERVAL).pipe(
      map(() => DateUtil.formatDistance(new Date(startDate), new Date())),
    )
  }
}
