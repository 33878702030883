import { ClipboardModule, Clipboard, } from '@angular/cdk/clipboard';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';

import { SafePipe } from '@core/base/layout';
import { NotificationService } from '@core/services';
import { GenericButtonComponent } from '../common/buttons';

@Component({
  standalone: true,
  selector: 'kt-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SafePipe,
    NgIf,
    GenericButtonComponent,
    ClipboardModule,
  ],
})
export class ImageComponent {
  private clipboard = inject(Clipboard);
  private notificationService = inject(NotificationService);

  protected imageUrl = '';
  protected imagePreviewUrl = '';
  protected showInfo = false;

  @Input() height?: number;
  @Input() width?: number;

  @Input() enableCopy = false;

  @Input() set url(url: string) {
    if (!this.imagePreviewUrl) {
      this.imagePreviewUrl = url;
    }

    this.imageUrl = url;
  }

  @Input() set preview(url: string) {
    if (!this.imageUrl) {
      this.imageUrl = url;
    }

    this.imagePreviewUrl = url;
  }


  handleMouseEnterEvent(data: any): void {
    if (this.enableCopy) {
      this.showInfo = true;
    }
  }

  handleMouseLeaveEvent(data: any): void {
    if (this.enableCopy) {
      this.showInfo = false;
    }
  }

  copyUrlToClipboard(url: string): void {
    const copied = this.clipboard.copy(url);

    if (copied) {
      this.notificationService.showInfo(`Image URL has been copied to clipboard`);
    } else {
      this.notificationService.showError(`Image URL has not been copied to clipboard`);
    }
  }
}
