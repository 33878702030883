import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kt-table-column',
  templateUrl: './table-column.component.html',
  styleUrls: ['./table-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnComponent {
  @Input() align?: 'start' | 'center' | 'end' | 'justify' | 'initial' = 'initial';
  @Input() bgColor?: string;
  @Input() textColor?: string;
}
