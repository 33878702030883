import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export enum Device {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop'
}

enum Orientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

interface ViewportInfo {
  height: number;
  width: number;
}

export interface DeviceInfo {
  device: Device;
  orientation: Orientation;
  height: number;
  width: number;
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private breakpointObserver = inject(BreakpointObserver);

  readonly deviceInfo$: Observable<DeviceInfo> = this.breakpointObserver.observe([
    Breakpoints.Handset,
    Breakpoints.Tablet
  ])
    .pipe(
      map((state) => this.buildDeviceInfo(state)),
    );

  get device$() {
    return this.deviceInfo$.pipe(
      distinctUntilChanged(),
      map(info => info.device),
    );
  }

  private buildDeviceInfo(state: BreakpointState): DeviceInfo {
    const size = {
      height: window.innerHeight,
      width: window.innerWidth,
    };

    return {
      device: this.detectDevice(state),
      orientation: this.detectOrientation(size),
      ...size,
    };
  }

  private detectDevice(state: BreakpointState): Device {
    if (
      state.breakpoints[Breakpoints.HandsetLandscape] ||
      state.breakpoints[Breakpoints.HandsetPortrait]
    ) {
      return Device.Mobile;
    }

    if (
      state.breakpoints[Breakpoints.TabletLandscape] ||
      state.breakpoints[Breakpoints.TabletPortrait]
    ) {
      return Device.Tablet;
    }

    return Device.Desktop;
  }

  private detectOrientation(size: Pick<ViewportInfo, 'height' | 'width'>): Orientation {
    return size.width > size.height ? Orientation.Landscape : Orientation.Portrait;
  }
}
