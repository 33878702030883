import { HttpErrorResponse } from '@angular/common/http';
import { AllOrNone } from '@coremodels/utils';
export class ApiResponse<T, K = any> {
  constructor(response: ApiResponse<T> | Error) {
    if (response instanceof HttpErrorResponse) {
      this.success = false;
      this.message = response.error.message;
    } else {
      this.success = true;
      this.message = response.message;
      this.metadata = (response as ApiResponse<T>).metadata;
      this.data = (response as ApiResponse<T>).data;
      this.total = (response as ApiResponse<T>).total;
    }
  }
  success: boolean;
  message: string;
  data!: T;
  metadata?: K;
  total!: number;
}

export const extractApiResponseData = <T>(response: ApiResponse<T>): T => response.data;

export class QueryResultsModel<T> {
  success?: boolean;
  message?: string;
  items?: T[];
  item?: T;
  summary?: any;
  totalCount?: number;
  errorMessage?: string;

  constructor(items: any[] = [], totalCount: number = 0, errorMessage: string = '', summary: any = {}) {
    this.items = items;
    this.totalCount = totalCount;
    this.summary = summary;
    this.errorMessage = errorMessage;
  }
}

export type SortDirection = 'asc' | 'desc';
export interface PageProps {
  pageIndex: number;
  pageSize: number;
}

export interface SortProps {
  sortDirection: SortDirection;
  sortField: string;
}

export type Page = PageProps & AllOrNone<SortProps>;

export interface PaginatedApiResponse<T = unknown> {
  records: T[];
  metadata: {
    totalRecords?: number;
    totalQueryRecords: number;
  };
}

export class QueryParamsModel {
  filter: any;
  sortOrder: SortDirection;
  sortField: string;
  pageIndex: number;
  pageSize: number;

  constructor(
    _filter: any,
    _sortOrder: SortDirection = 'asc',
    _sortField: string = '',
    _pageIndex: number = 0,
    _pageSize: number = 10) {
    this.filter = _filter;
    this.sortOrder = _sortOrder;
    this.sortField = _sortField;
    this.pageIndex = _pageIndex;
    this.pageSize = _pageSize;
  }
}

