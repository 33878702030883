import { User } from '@core/entities/user';
import { AuthActions, AuthActionTypes } from '../_actions/auth.actions';

export interface AuthState {
  loggedIn: boolean;
  authToken: string | undefined;
  user: User | undefined;
  isUserLoaded: boolean;
  isadmin: boolean;
}

export const initialAuthState: AuthState = {
  loggedIn: false,
  authToken: undefined,
  user: undefined,
  isUserLoaded: false,
  isadmin: false
};

export const authReducer = (state = initialAuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case AuthActionTypes.Login: {
      return {
        loggedIn: true,
        authToken: action.payload.authToken,
        user: undefined,
        isUserLoaded: false,
        isadmin: action.payload.isAdmin,
      };
    }

    case AuthActionTypes.Register: {
      return {
        loggedIn: true,
        authToken: action.payload.authToken,
        user: undefined,
        isUserLoaded: false,
        isadmin: action.payload.isAdmin,
      };
    }

    case AuthActionTypes.Logout:
      return {
        ...initialAuthState,
        isadmin: state.isadmin,
      };

    case AuthActionTypes.UserLoaded: {
      return {
        ...state,
        user: action.payload.user,
        isUserLoaded: true
      };
    }

    default:
      return state;
  }
}
