import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  standalone: true,
  selector: 'kt-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgFor,
    NgTemplateOutlet,
  ],
})
export class TooltipComponent {
  protected message?: string;
  protected messages?: string[];
  protected template?: TemplateRef<any>;
  @Input() set data(data: string | string[] | TemplateRef<any>) {

    if (typeof data === 'string') {
      this.message = data;
    } else if (Array.isArray(data)) {

      if (data.length === 1) {
        this.message = data[0];
      } else {
        this.messages = data;
      }
    } else if (data.elementRef) {
      this.template = data;
    }
  };
}
