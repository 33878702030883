import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, } from 'rxjs/operators';

import { ApiService, CrudService } from '@coreservices/api';
import {
  ApiResponse,
  ScanningResult,
  ScanningStation,
  ScanningStationFilter,
} from '@core/models';

const rootEndpoint = '/scanning';
const endpoint = `${rootEndpoint}/stations`;

@Injectable({
  providedIn: 'root'
})
export class ScanningStationService extends CrudService<ScanningStation, ScanningStationFilter> {

  constructor(
    protected override http$: HttpClient,
    protected override apiService: ApiService,
  ) {
    super(apiService, http$);
  }

  get endpoint(): string {
    return endpoint;
  }

  loadStationById(stationId: string): Observable<ScanningStation> {
    const url = `/scanning/stations/${stationId}`;
    return this.apiService.get<ScanningStation>(url).pipe(
      map((response: ApiResponse<ScanningStation>) => response.data),
    );
  }

  removeItemProductionState(factoryItemId: string, stationId: string): Observable<ScanningResult[]> {
    const url = `/scanning/item/${factoryItemId}/stationId/${stationId}`;
    return this.http$.delete<ApiResponse<ScanningResult[]>>(url).pipe(
      map((response: ApiResponse<ScanningResult[]>) => response?.data),
      catchError((error: any) => throwError(error)),
    );
  }
}
