import { Entity } from '@core/entities/generic';

export enum UserRole {
  superuser = 'superuser',
  admin = 'admin',
  supervisor = 'supervisor',
  regular = 'regular',
}

export enum UserType {
  standard = 'standard',
  virtual = 'virtual',
}

export interface User extends Entity {
  avatar?: string;
  email?: string;
  name: string;
  productionStages?: string[];
  tasks: string[];
  role: UserRole;
  type: UserType;
  workstation?: boolean;
  registered: boolean;
}

export type CreateUserDto = User;
export type UpdateUserDto = Pick<User, 'avatar' | 'name' | 'productionStages' | 'role' | 'workstation' | 'tasks'>;

export interface QueryUserDto {
  name?: string;
  email?: string;
  type?: UserType;
  stageId?: string;
  search?: string;
  role?: UserRole | UserRole[]; 
}

export interface UsersResponse {
  data: User[];
  total: number;
}
