import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { ApiResponse } from '@coremodels';
import { ApiService } from './api.service';
import { CacheService } from '@core/common';
import { HttpClient } from '@angular/common/http';

export interface CrudOperations<T, K = T> {
  create(item: { [key: string]: T }): Observable<ApiResponse<T>>;
  update(id: string, payload: Partial<T>): Observable<ApiResponse<T>>;
  findOne(id: string): Observable<T>;
  findAll(filter?: Partial<K>): Observable<ApiResponse<T[]>>;
  delete(id: string): Observable<ApiResponse<T>>;
}

@Injectable({
  providedIn: 'root'
})
export abstract class CrudService<T, K = T> extends CacheService<T[]> implements CrudOperations<T, K> {
  constructor(
    protected apiService: ApiService,
    protected http$: HttpClient,
  ) {
    super();
  }

  abstract get endpoint(): string;

  findAll(
    filter?: Partial<K> | { [key: string]: any },
    fields?: string[],
  ): Observable<ApiResponse<T[]>> {
    return this.apiService.get<T[]>(this.endpoint, filter, { fields }).pipe(
      catchError(() => of({} as ApiResponse<T[]>)),
    );
  }

  getAll(takeUntilObs$: Observable<any>): Observable<T[]> {
    return this.getCachedData(
      () => this.findAll().pipe(
        map((response: any) => response.data),
        takeUntil(takeUntilObs$)
      ),
    );
  }

  findOne(itemId: string): Observable<T> {
    return this.http$.get<T>(`${this.endpoint}/${itemId}`);
  }

  create(item: { [key: string]: Partial<T> }): Observable<ApiResponse<T>> {
    return this.apiService.post<T>(this.endpoint, item);
  }

  update(itemId: string, payload: Partial<T>): Observable<ApiResponse<T>> {
    return this.apiService.put<T>(`${this.endpoint}/${itemId}`, { payload });
  }

  delete(itemId: string): Observable<ApiResponse<T>> {
    return this.apiService.delete<T>(`${this.endpoint}/${itemId}`);
  }
}
