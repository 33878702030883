<div class="page">
  <h2 mat-dialog-title>Select employee</h2>
  <mat-dialog-content class="mat-typography">
    <div class="sections">
      <ng-container *ngIf="latestSelectedUsers.length">
        <div class="section">
          <div class="section__header">
            <p>Latest selected</p>
          </div>
          <ng-container *ngTemplateOutlet="userTpl; context: { users: latestSelectedUsers }"></ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="users$ | async as users">
        <div class="section">
          <div class="section__header">
            <p>All employees</p>

            <mat-form-field class="search-field">
              <mat-label>Search</mat-label>
              <input matInput
                     type="text"
                     #search
                     (keyup)="userInput$.next($any($event.target)?.value)">
              <button *ngIf="userInput$.getValue()"
                      matSuffix
                      mat-icon-button
                      aria-label="Clear"
                      (click)="userInput$.next(undefined);search.value = ''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <ng-container *ngTemplateOutlet="userTpl; context: { users: users }"></ng-container>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <kt-generic-button mat-dialog-close>Cancel</kt-generic-button>
  </mat-dialog-actions>
</div>

<ng-template #userTpl
             let-users="users">
  <div class="centered">
    <section class="cards">
      <article class="card"
               *ngFor="let user of users"
               (click)="selectUser(user)">
        <kt-generic-avatar [user]="user"></kt-generic-avatar>
        <div class="card-content">
          <h2>{{ user.name }}</h2>
        </div>
      </article>
    </section>
  </div>
</ng-template>