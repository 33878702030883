import { FileExtension } from "@core/entities/asset";

export class FileUtil {
  static readonly IMAGE_FILE_EXTENSIONS_MAP: { [mime: string]: string } = {
    'image/bmp': 'bmp',
    'image/gif': 'gif',
    'image/jpeg': 'jpg',
    'image/jpg': 'jpg',
    'image/png': 'png',
    'image/svg+xml': 'svg',
    'application/pdf': 'pdf',
  };

  static getFileExtension(name: string): FileExtension {
    return (name.split(/[#?]/)[0]?.split('.')?.pop()?.trim() || '') as FileExtension;
  }

  static getFileName(url: string) {
    const filename = url.substring(url.lastIndexOf("/") + 1);
    const fileExtension = this.getFileExtension(url);

    console.log(filename, fileExtension, filename.replace(`.${fileExtension}`, ''))

    return filename.replace(`.${fileExtension}`, '');
  }

  static getFileMetadata(file: File): { extension: string; mime: string } {
    if (!FileUtil.IMAGE_FILE_EXTENSIONS_MAP[file?.type]) {
      const extension = FileUtil.getFileExtension(file.name);
      return {
        extension,
        mime: 'application/octet-stream',
      };
    }

    return {
      extension: FileUtil.IMAGE_FILE_EXTENSIONS_MAP[file.type],
      mime: file.type,
    };
  };
}
