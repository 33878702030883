import { Directive, ElementRef, HostListener, inject } from '@angular/core';
import { StringUtil } from '@core/utils';

@Directive({
  standalone: true,
  selector: '[inputTrim]'
})
export class InputTrimDirective {
  private el = inject(ElementRef);

  @HostListener('blur') onBlur() {
    const value = this.el.nativeElement.value;
    const valueTrim = StringUtil.trim(value);
    if (value !== valueTrim) {
      this.el.nativeElement.value = valueTrim;
    }
  }

}
