import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeMillisFormat implements PipeTransform {
  transform(
    value: number | string,
    format: string = 'hms'): any {
    return this.formatMillis(+value, format);
  }

  formatMillis(millisecondsValue: number, format: string): string {
    let seconds = Math.round(millisecondsValue / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours: number | null = null;
    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      minutes = minutes - (hours * 60);
    }

    seconds = Math.floor(seconds % 60);
    return format.split('').reduce((acc, param) => {
      switch (param) {
        case 'h':
          if (!!hours) {
            acc += `${hours}h `;
          }
          break;
        case 'm':
          if (!!minutes) {
            acc += `${minutes}m `;
          }
          break;
        case 's':
          if (!!seconds) {
            acc += `${seconds}s`;
          }
          break;
      }
      return acc;
    }, '');
  }
}
