import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import mermaid from 'mermaid';

@Component({
  standalone: true,
  selector: 'kt-mermaid-renderer',
  template: `<div class="mermaid" #mermaidTpl></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MermaidRendererComponent implements OnInit {
  @ViewChild('mermaidTpl', { static: true }) protected mermaidTpl: ElementRef | undefined;

  @Input() set code(definition: string) {
    this.renderGraph(definition);
  }

  ngOnInit(): void {
    mermaid.initialize({
      securityLevel: 'loose',
      startOnLoad: false,
    });
  }

  private async renderGraph(definition: string) {
    if (!this.mermaidTpl) {
      console.warn(`Mermaid render template is not ready. Skipping rendering.`);
      return;
    }

    if (!definition) {
      console.warn(`Mermaid graph definition is required. Skipping rendering.`);
      return;
    }

    const outputElement = this.mermaidTpl.nativeElement;
    const result = await mermaid.render('graphDiv', definition);
    outputElement.innerHTML = result.svg;
  }
}
