import printJS from 'print-js';
import { FileUtil } from './file.util';

const CONFIG = {
  frameId: 'PRINTABLE_FRAME_CONTAINER',
  imageStyle: 'max-width: 100%',
};

export enum PrintableFormat {
  jpg = 'jpg',
  jpeg = 'jpeg',
  png = 'png',
}


export const printImages = (printable: string | string[], callback?: () => void): void => {

  const existedFrame = document.getElementById(CONFIG.frameId);

  if (existedFrame) {
    existedFrame.parentNode?.removeChild(existedFrame);
  }

  const printFrame = document.createElement('iframe');
  printFrame.setAttribute('style', 'visibility: hidden; height: 0; width: 0; position: absolute; border: 0');
  printFrame.setAttribute('id', CONFIG.frameId);
  printFrame.srcdoc = '<html><head></head><body></body></html>';

  if (!Array.isArray(printable)) {
    printable = [printable];
  }

  printable.forEach(imageUrl => {
    const extension = FileUtil.getFileExtension(imageUrl);
    if (!Object.keys(PrintableFormat).includes(extension)) {
      throw new Error(`Image file format is not supported: ${extension}`);
    }
  });

  const printableElement = document.createElement('div');

  printable.forEach(src => {
    const img = document.createElement('img');
    img.setAttribute('style', CONFIG.imageStyle);
    img.src = src;

    const imageWrapper = document.createElement('div');
    imageWrapper.appendChild(img);

    printableElement.appendChild(imageWrapper);
  })

  document.getElementsByTagName('body')[0].appendChild(printFrame);
  const iframeElement = document.getElementById(CONFIG.frameId) as HTMLIFrameElement;

  iframeElement.onload = () => {

    let printDocument = ((iframeElement as any).contentWindow || (iframeElement as any).contentDocument);
    if (printDocument.document) {
      printDocument = printDocument.document;
    }

    printDocument.body.appendChild(printableElement);

    const images = printDocument.getElementsByTagName('img') as HTMLImageElement[];

    if (images.length) {
      loadIframeImages(Array.from(images)).then(() => performPrint(iframeElement, callback));
    } else {
      performPrint(iframeElement, callback);
    }
  }
};

export const printPDF = (printable: string, callback?: () => void): void => {
  const pdfWindow = window.open(printable);
  if (pdfWindow) {
    pdfWindow.onload = function () {
      pdfWindow.focus();
      pdfWindow.print();
      if (callback && typeof callback === 'function') {
        callback();
      }

    };
  }
};


const performPrint = (iframeElement: HTMLIFrameElement, callback?: () => void) => {
  try {
    iframeElement.focus()
    iframeElement.contentWindow?.print()
  } catch (error) {
    throw error;
  } finally {
    if (callback && typeof callback === 'function') {
      callback();
    }
  }
}

const loadIframeImages = async (images: HTMLImageElement[]) => {
  const promises = images
    .filter(image => image.src && image.src !== window.location.href)
    .map((image) => loadIframeImage(image))

  return Promise.all(promises);
}

const loadIframeImage = async (image: HTMLImageElement): Promise<void> => {
  return new Promise(resolve => {
    const pollImage = () => {
      if (!image || typeof image.naturalWidth === 'undefined' || image.naturalWidth === 0 || !image.complete) {
        setTimeout(pollImage, 500);
      } else {
        resolve();
      }
    }
    pollImage();
  })
}

export class DownloadUtil {
  public static async printPDF(url: string, filename?: string | null, callback?: any) {

    const config: printJS.Configuration = {
      printable: url,
      documentTitle: filename || FileUtil.getFileName(url) || 'Document',
      onPrintDialogClose: () => {
        console.log('!', typeof callback === 'function')
        if (typeof callback === 'function') {
          // clearInterval(focuser);
          callback();
        }
      }
    }

    // let focuser = setInterval(() => window.dispatchEvent(new Event('focus')), 1500);
    printJS(config);
  }
}
