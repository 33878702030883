// Anglar
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const MaterialModules = [
  MatProgressSpinnerModule,
];
// Layout Directives
// Services
import {
  ContentAnimateDirective,
  FirstLetterPipe,
  GetObjectPipe,
  HeaderDirective,
  JoinPipe,
  MenuDirective,
  OffcanvasDirective,
  SafePipe,
  ScrollTopDirective,
  StickyDirective,
  ToggleDirective,
  TimeMillisFormat,
  ContenteditableValueAccessorDirective,
  HasRoleDirective,
} from './base/layout';
import {
  SecurePipe,
  TruncatePipe,
  FaDatePipe,
  ReplaceAllPipe,
  SortByPipe,
  ArrayStatsPipe,
  DateToAgePipe,
  DurationAsyncPipe,
  ObjectPathPipe,
  PluralizePipe,
} from './pipes';

import {
  AttachDeviceWidthDirective,
  AutofocusDirective,
  DragDropDirective,
  FileValueAccessorDirective,
  IfDeviceDirective,
  LoadingDirective,
  TooltipDirective,
} from '@core/directives';
import { TopbarTemplateDirective } from '@core/components/topbar';
import { NoScrollDirective } from './directives/no-scroll.directive';

const DIRECTIVES = [
  ScrollTopDirective,
  HeaderDirective,
  OffcanvasDirective,
  ToggleDirective,
  MenuDirective,
  ContentAnimateDirective,
  StickyDirective,
  ContenteditableValueAccessorDirective,
  LoadingDirective,
  AutofocusDirective,
  FileValueAccessorDirective,
];
const PIPES = [
  TimeMillisFormat,
  JoinPipe,
  GetObjectPipe,
  FirstLetterPipe,
  SecurePipe,
  TruncatePipe,
  SortByPipe,
];

const MODULES = [
  ArrayStatsPipe,
  AttachDeviceWidthDirective,
  DateToAgePipe,
  DragDropDirective,
  DurationAsyncPipe,
  FaDatePipe,
  HasRoleDirective,
  IfDeviceDirective,
  NoScrollDirective,
  ObjectPathPipe,
  PluralizePipe,
  ReplaceAllPipe,
  SafePipe,
  TooltipDirective,
  TopbarTemplateDirective,
];

@NgModule({
  imports: [
    CommonModule,
    ...MaterialModules,
    ...MODULES,
  ],
  declarations: [
    ...DIRECTIVES,
    ...PIPES,
  ],
  exports: [
    ...MODULES,
    ...DIRECTIVES,
    ...PIPES,
  ],
  providers: [
    ...PIPES,
    DatePipe,
  ]
})
export class CoreModule {
}
