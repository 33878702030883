import { NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import KeenSlider, { KeenSliderInstance } from 'keen-slider'
import { GenericButtonComponent } from '../common';

@Component({
  standalone: true,
  selector: 'kt-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: [
    './image-slider.component.scss',
    '../../../../../node_modules/keen-slider/keen-slider.min.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    NgIf,
    GenericButtonComponent,
  ],
})
export class ImageSliderComponent implements AfterViewInit, OnDestroy {
  private cdRef = inject(ChangeDetectorRef);
  @Input() data?: string[];
  @Output() selectImage = new EventEmitter<number>();
  @ViewChild("ref") ref!: ElementRef<HTMLElement>

  currentSlide: number = 0
  slider: KeenSliderInstance | null = null

  ngAfterViewInit() {
    this.slider = new KeenSlider(this.ref.nativeElement, {
      slides: {
        number: this.data?.length || 0,
        perView: 3,
        spacing: 0,
      },
      loop: false,
      defaultAnimation: { duration: 1000 },
      slideChanged: slide => {
        this.currentSlide = slide.track?.details?.rel || 0;
        this.cdRef.markForCheck();
      },
    });
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }
}
