import { cloneDeep, get } from 'lodash';

export class ObjectUtil {
  static shallowEqual(source: { [key: string]: any }, target: { [key: string]: any }): boolean {
    const sourceKeys = Object.keys(source);
    const targeteys = Object.keys(target);

    if (sourceKeys.length !== targeteys.length) {
      return false;
    }
    for (const key of sourceKeys) {
      if (source[key] !== target[key]) {
        return false;
      }
    }
    return true;
  }

  static getValueByPath(object: { [key: string | number | symbol]: any }, path: string, defaultValue?: string): any {
    // TODO: implement custom function to get object's value by given prop and then remove loadash lib
    return get(object, path, defaultValue);
  }

  static isObject(object: unknown): boolean {
    return typeof object === 'object' &&
      !Array.isArray(object) &&
      object !== null;
  }

  static shallowClone(object?: object) {
    return Object.assign({}, object || {});
  }

  static deepClone(object: unknown) {
    return cloneDeep(object);
  }
}
