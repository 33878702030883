// NGRX
import { createSelector } from '@ngrx/store';
// Models
import { AuthState } from '@coreauth/_reducers/auth.reducers';

export const selectAuthState = (state: any) => {
  return state.auth as AuthState;
}

export const isLoggedIn = createSelector(
  selectAuthState,
  auth => auth.loggedIn
);

export const getUserRole = createSelector(
  selectAuthState,
  auth => auth.user?.role,
);

export const isAdmin = createSelector(
  selectAuthState,
  auth => auth.isadmin
);

export const isUserLoaded = createSelector(
  selectAuthState,
  auth => auth.isUserLoaded
);

export const currentUser = createSelector(
  selectAuthState,
  auth => auth.user
);

export const currentUserRole = createSelector(
  currentUser,
  user => user?.role,
);

export const isWorkstation = createSelector(
  currentUser,
  user => Boolean(user?.workstation),
);
