export enum MermaidDiagramType {
  Flowchart = 'flowchart',
  SequenceDiagram = 'sequenceDiagram',
  ClassDiagram = 'classDiagram',
  StateDiagramV2 = 'stateDiagram-v2',
  EntityRelationDiagram = 'erDiagram',
  Journey = 'journey',
  Gantt = 'gantt',
  Pie = 'pie',
  QuadrantChart = 'quadrantChart',
  RequirementDiagram = 'requirementDiagram',
  GitGraph = 'gitGraph',
  C4Context = 'C4Context',
  Mindmap = 'mindmap',
  Timeline = 'timeline',
  Zenuml = 'zenuml',
}

export enum MermaidFlowchartDirection {
  TopToBottom = 'TB',
  LeftToRight = 'LR',
  RightToLeft = 'RL',
  BottomToTop = 'BT',
}
