<ng-container *ngIf="documents.length; else noAttachments">
  <div class="documents">
    <div class="document"
         *ngFor="let doc of documents; let i = index">
      <div class="document_title">{{ doc.title }}</div>
      <div class="document_actions">
        <kt-generic-button appearance="icon"
                           [disabled]="!doc.downloadable"
                           (click)="downloadFile(doc)">download</kt-generic-button>
        <kt-generic-button mat-icon-button
                           [matMenuTriggerFor]="menu"
                           *ngIf="doc.previewable || doc.removable"
                           appearance="icon">more_vert</kt-generic-button>

        <mat-menu #menu="matMenu">

          <kt-generic-button appearance="mat-menu-item"
                             [disabled]="!doc.previewable"
                             icon="preview"
                             (click)="openPreview(doc.imgIdx!)">Preview</kt-generic-button>

          <kt-generic-button appearance="mat-menu-item"
                             [disabled]="!doc.removable"
                             icon="delete"
                             (click)="removeAttachment(i)">Delete</kt-generic-button>
        </mat-menu>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noAttachments>
  No attachment
</ng-template>