import { StringUtil } from "@coreutils";

export enum OrderStatusValue {
  awaiting_payment = 'awaiting_payment',
  payment_failed = 'payment_failed',
  validation_failed = 'validation_failed',
  verified = 'verified',
  submitted = 'submitted',
  cancelled = 'cancelled',
  on_hold = 'on_hold',
  in_production = 'in_production',
  shipped = 'shipped',
  shipping_label_created = 'shipping_label_created',
  quality_passed = 'quality_passed',
}

export const OrderStatuses = Object.entries(OrderStatusValue).map(([key, value]) => ({
  title: StringUtil.capitalizeAll(key.replaceAll('_', ' ')),
  value: value,
}));

export interface OrderStatus {
  awaiting_payment: string;
  payment_failed: string;
  validation_failed: string;
  verified: string;
  submitted: string;
  cancelled: string;
  on_hold: string;
  in_production: string;
  shipped: string;
  shipping_label_created: string;
  [key: string]: string;
}

export enum OrderCancelReason {
  customer_cancel = 'customer_cancel',
  customer_order_change = 'customer_order_change',
  inappropriate_content = 'inappropriate_content',
  order_duplicate = 'order_duplicate',
  order_issue = 'order_issue',
  order_item_incorrect = 'order_item_incorrect',
  order_item_quality = 'order_item_quality',
  other_reason = 'other_reason',
  unknown_product = 'unknown_product',
  unknown_reason = 'unknown_reason',
  shipping_address_invalid = 'shipping_address_invalid',
  shipping_method_not_supported = 'shipping_method_not_supported',
}
