import { Component } from '@angular/core';

@Component({
  selector: 'kt-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent {

}
