import { HttpParams } from "@angular/common/http";

export enum ApiFields {
  fields = '$resource.fields',
  joinFields = '$resource.join-fields',
}

export class ApiUtil {
  static appendFields(params: HttpParams, includes: string[] | undefined, prop: ApiFields = ApiFields.fields): HttpParams {
    if (!(Array.isArray(includes) && includes.length)) {
      return params;
    }

    return includes.reduce((params, field) => {
      if (!field) {
        return params;
      }

      return params.append(prop, String(field));

    }, params);
  }

  static appendJoinFields(params: HttpParams, includes: string[]): HttpParams {
    return ApiUtil.appendFields(params, includes, ApiFields.joinFields);
  }
}