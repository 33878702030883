import { NgStyle, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Priority } from '@core/entities/priority';

@Component({
  standalone: true,
  selector: 'kt-priority',
  templateUrl: './priority.component.html',
  styleUrls: ['./priority.component.scss'],
  imports: [
    NgSwitch,
    NgSwitchCase,
    MatIconModule,
    MatTooltipModule,
    NgStyle,
  ],
})
export class PriorityComponent {
  readonly Priority = Priority;

  protected priorityLevel: Priority = Priority.medium;
  @Input() set level(level: Priority) {
    this.priorityLevel = this.levels.includes(level) ? level : Priority.medium;
  };

  private levels = Object.values(Priority);
}
