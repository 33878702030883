import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
@Injectable({
  providedIn: 'root'
})
export class ApiUtilsService {
  /*
   * get standard content-type
   */
  getHTTPHeaders(): HttpHeaders {
    const token = localStorage.getItem(environment.authTokenKey) || '';
    const result = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token,
    });
    return result;
  }

  addAuthHeaders(headers?: HttpHeaders): HttpHeaders {
    const token = localStorage.getItem(environment.authTokenKey);

    if (headers && headers instanceof HttpHeaders) {
      return headers.set('Authorization', token || '');
    }
    const result = new HttpHeaders({
      Authorization: token || '',
    });
    return result;
  }

  convertObjectToHttpParams(params: { [key: string]: string | Date | number | any[]; } = {}): HttpParams {
    let httpParams = new HttpParams();
    if (!params) {
      return httpParams;
    }
    for (const key of Object.keys(params)) {
      if (typeof params[key] !== 'number' && !params[key]) {
        continue;
      }

      if (params[key] instanceof Date) {
        httpParams = httpParams.append(key, (params[key] as Date).toISOString());
        continue;
      }

      if (!!Array.isArray(params[key])) {
        for (const value of params[key] as any[]) {
          httpParams = httpParams.append(`${key}[]`, value);
        }
        continue;
      }

      if (typeof params[key] === 'object') {
        httpParams = httpParams.append(key, JSON.stringify(params[key]));
        continue;
      }

      httpParams = httpParams.append(key, String(params[key]));
    }

    return httpParams;
  }
}
