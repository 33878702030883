import { Route } from '@angular/router';
import { RouteInfo } from '@corecomponents/topbar/navigator';
import { UserRole } from '@core/entities/user';

export interface AppRouteMenuConfig extends Partial<Pick<RouteInfo, 'title' | 'titleResolver'>> {
  icon?: string;
}

export interface AppRouteData {
  roles?: UserRole[];
  menuConfig?: AppRouteMenuConfig;
}

export interface AppRoute extends Route {
  children?: AppRoutes;
  data?: AppRouteData;
}

export type AppRoutes = AppRoute[];

export class AppRouteError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AppRouterError';
  }
}
