import { AsyncPipe, NgFor, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { BehaviorSubject, Subject, } from 'rxjs';
import { map, switchMap, takeUntil, } from 'rxjs/operators';

import { SessionStorageUtil } from '@core/utils';
import { UserService, QueryUserDto, User } from '@core/entities/user';
import { GenericButtonComponent, GenericAvatarComponent } from '@shared/ui';

@Component({
  standalone: true,
  selector: 'kt-select-virtual-user',
  templateUrl: './select-virtual-user.component.html',
  styleUrls: ['./select-virtual-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    FormsModule,
    GenericAvatarComponent,
    GenericButtonComponent,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgFor,
    NgIf,
    NgStyle,
    NgTemplateOutlet,
  ],
})
export class SelectVirtualUserComponent implements OnInit, OnDestroy {
  static STORAGE_KEY = 'LATEST_VIRTUAL_USERS';
  private userService = inject(UserService)
  private dialogRef = inject<MatDialogRef<SelectVirtualUserComponent>>(MatDialogRef);
  readonly query?: QueryUserDto | undefined = inject(MAT_DIALOG_DATA);
  readonly latestSelectedUsers = this.getUsersFromSessionStorage();
  readonly usersSubject$ = new BehaviorSubject<User[]>([]);
  readonly onDestroy$ = new Subject<void>();
  readonly userInput$ = new BehaviorSubject<any | undefined>(undefined);
  readonly users$ = this.userInput$.pipe(
    switchMap(text => this.usersSubject$.asObservable().pipe(
      map(users => {
        if (text) {
          return users.filter(user => user.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()))
        }

        return users;
      })
    ))
  );

  ngOnInit(): void {
    this.userService.getUsers({ ...this.query, pageSize: 0, sortField: 'name', sortDirection: 'asc' }).pipe(
      map(r => r.data),
      takeUntil(this.onDestroy$),
    ).subscribe(
      users => this.usersSubject$.next(users)
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  selectUser(user: User) {
    this.addUserToSessionStorage(user);
    this.dialogRef.close(user.id);
  }

  private getUsersFromSessionStorage(): User[] {
    return SessionStorageUtil.get<User[]>(SelectVirtualUserComponent.STORAGE_KEY) || [];
  }

  private addUserToSessionStorage(user: User): void {
    const users = (SessionStorageUtil.get<User[]>(SelectVirtualUserComponent.STORAGE_KEY) || []).filter(latestUser => latestUser.id !== user.id);
    users.unshift(user);

    SessionStorageUtil.set(SelectVirtualUserComponent.STORAGE_KEY, users, true)
  }
}
