import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';

const CACHE_KEY = 'DEFAULT';
const CACHE_SIZE = 1;
const REFRESH_INTERVAL = 10 * 60 * 1000; // 10 minutes

@Injectable({
  providedIn: 'root'
})
export abstract class CacheService<T> {



  private cache: Record<string, Observable<any>> = {};

  getCachedData(
    handler: () => Observable<T>,
    key: string = CACHE_KEY,
    refreshInterval: number = REFRESH_INTERVAL,
  ) {
    if (!this.cache[key]) {
      this.cache[key] = timer(0, refreshInterval).pipe(
        switchMap(() => handler()),
        shareReplay(CACHE_SIZE),
      );
    }

    return this.cache[key];
  }

}
