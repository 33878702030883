<ng-container [ngSwitch]="priorityLevel">
  <ng-container *ngSwitchCase="Priority.high">
    <mat-icon
      [style.transform]="'rotate(-90deg)'"
      matTooltip="High priority"
      class="level high"
    >double_arrow</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="Priority.medium">
    <mat-icon
      matTooltip="Medium priority"
      class="level medium"
    >equal</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="Priority.low">
    <mat-icon
      [style.transform]="'rotate(90deg)'"
      matTooltip="Low priority"
      class="level low"
    >double_arrow</mat-icon>
  </ng-container>
</ng-container>
