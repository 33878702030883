// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
// NGRX
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import { AuthActionTypes, Login, Logout, Register, UserLoaded, UserRequested } from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded, } from '../_selectors/auth.selectors';

@Injectable()
export class AuthEffects implements OnInitEffects {
  login$ = createEffect(() => this.actions$.pipe(
    ofType<Login>(AuthActionTypes.Login),
    tap(action => {
      localStorage.setItem(environment.authTokenKey, action.payload.authToken);
      localStorage.setItem('isAdmin', action.payload.isAdmin ? '1' : '0');
      if (action.payload.user) {
        this.store.dispatch(new UserLoaded({ user: action.payload.user }));
      } else {
        this.store.dispatch(new UserRequested());
      }
    }),
  ), { dispatch: false });

  logout$ = createEffect(() => this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(() => {
      localStorage.removeItem(environment.authTokenKey);
      this.router.navigate(['/auth/login'],
        { queryParams: { returnUrl: '/' } });
    })
  ), { dispatch: false });

  register$ = createEffect(() => this.actions$.pipe(
    ofType<Register>(AuthActionTypes.Register),
    tap(action => {
      localStorage.setItem(environment.authTokenKey, action.payload.authToken);
      localStorage.setItem('isAdmin', action.payload.isAdmin ? '1' : '0');
    })
  ), { dispatch: false });

  loadUser$ = createEffect(() => this.actions$.pipe(
    ofType<UserRequested>(AuthActionTypes.UserRequested),
    withLatestFrom(this.store.pipe(select(isUserLoaded))),
    filter(([_, userLoaded]) => !userLoaded),
    mergeMap(() => this.auth.getUserByToken()),
    tap(user => {
      if (!!user) {
        this.store.dispatch(new UserLoaded({ user }));
      } else {
        this.store.dispatch(new Logout());
      }
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router,
    private auth: AuthService,
    private store: Store<AppState>,
  ) {}

  ngrxOnInitEffects(): Action {
    const userToken = localStorage.getItem(environment.authTokenKey);
    const isAdmin = !!(localStorage.getItem('isAdmin'));
    let observableResult = { type: 'NO_ACTION' };
    if (userToken) {
      observableResult = new Login({ authToken: userToken, isAdmin, user: undefined });
    }
    return observableResult;
  }
}
