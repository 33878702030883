import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuConfigService } from './menu-config.service';
import { ObjectUtil } from '@coreutils';

@Injectable()
export class MenuAsideService {
  private menuListSubject$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  readonly menuList$: Observable<any[]> = this.menuListSubject$.asObservable();

  constructor(private menuConfigService: MenuConfigService) {
    this.loadMenu();
  }

  private loadMenu() {
    const menuItems: any[] = ObjectUtil.getValueByPath(this.menuConfigService.getMenus(), 'aside.items');
    this.menuListSubject$.next(menuItems);
  }
}
