import {
  Directive,
  OnInit,
  OnDestroy,
  Input,
  ViewContainerRef,
  TemplateRef,
  ChangeDetectorRef,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { UserRole } from '@core/entities/user';
import { currentUserRole } from '@coreauth/_selectors/auth.selectors';
import { AuthState } from '@coreauth/_reducers/auth.reducers';

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appHasRoles]',
  exportAs: 'appHasRoles',
})
export class HasRoleDirective implements OnInit, OnDestroy {
  // the roles the user must have
  @Input() appHasRoles?: string[];

  readonly onDestroy$ = new Subject();

  isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private store$: Store<AuthState>,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    //  We subscribe to the roles$ to know the roles the user has
    this.store$.pipe(
      select(currentUserRole),
      takeUntil(this.onDestroy$)
    ).subscribe((userRole?: UserRole) => {
      // If he doesn't have any roles, we clear the viewContainerRef
      if (!userRole) {
        this.viewContainerRef.clear();
      }
      // If the user has the role needed to
      // render this component we can add it
      if (!this.appHasRoles || userRole && this.appHasRoles.includes(userRole)) {
        // If it is already visible (which can happen if
        // his roles changed) we do not need to add it a second time
        if (!this.isVisible) {
          // We update the `isVisible` property and add the
          // templateRef to the view using the
          // 'createEmbeddedView' method of the viewContainerRef
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        // If the user does not have the role,
        // we update the `isVisible` property and clear
        // the contents of the viewContainerRef
        this.isVisible = false;
        this.viewContainerRef.clear();
      }

      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }
}
