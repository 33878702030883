import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material.module';
import { FactoryAlertComponent } from './factory-alert.component';

@NgModule({
  declarations: [FactoryAlertComponent],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    FactoryAlertComponent,
  ],
})
export class FactoryAlertModule { }
