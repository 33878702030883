import { OverlayRef, Overlay, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  inject,
} from '@angular/core';
import { TooltipComponent } from './tooltip.component';

@Directive({
  standalone: true,
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
  static readonly POSITION: ConnectedPosition = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 5,
  };

  private overlay = inject(Overlay);
  private elementRef = inject(ElementRef);

  @Input() showToolTip = true;
  @Input() set position(data: ConnectedPosition) {
    if (!data || !this.showToolTip) {
      return;
    }

    this.createOverlay(data);
  };
  @Input('appTooltip') data?: string | string[] | TemplateRef<any>;

  private overlayRef?: OverlayRef;

  ngOnInit() {

    if (!this.showToolTip) {
      return;
    }

    this.createOverlay(TooltipDirective.POSITION);
  }

  private createOverlay(position: ConnectedPosition): void {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([position]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  show() {
    if (this.overlayRef && !this.overlayRef.hasAttached() && this.data) {
      const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
      tooltipRef.instance.data = this.data;
    }
  }

  @HostListener('mouseleave')
  hide() {
    this.closeToolTip();
  }

  ngOnDestroy() {
    this.closeToolTip();
  }

  private closeToolTip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

}
