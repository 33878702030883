import * as XLSX from 'xlsx';
import { DateUtil } from './dates/date.utils';

interface DateOptions {
  fields: string[];
  format?: string;
};

export interface Json2xlsConfig {
  dateOptions?: DateOptions;
  fileName?: string;
  header?: string[];
  sheetName?: string;
}

const DEFAULT_DATE_FORMAT = 'dd-MM-yyyy_hh:mm';
const DEFAULT_SHEET_NAME = 'Sheet1';

const convertStringToDate = (item: any, dateOptions: DateOptions) => {
  return dateOptions.fields.reduce(
    (acc, field) => {

      if (Object.keys(item).includes(field)) {
        return {
          ...acc,
          [field]: item[field] ? new Date(item[field]) : '',
        };
      }

      return acc;
    },
    { ...item });
};

export const json2xls = <T = any>(data: T[], config: Json2xlsConfig = {}): void => {
  if (config.dateOptions) {
    data = data.map(row => convertStringToDate(row, config.dateOptions as DateOptions));
  }
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
    data,
    {
      ...(config.header && { header: config.header }),
      dateNF: config?.dateOptions?.format || DEFAULT_DATE_FORMAT,
      cellDates: true,
    }
  );
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, config.sheetName || DEFAULT_SHEET_NAME);
  const filename = config.fileName || new DateUtil().format('%YYYY-%MM-%DD_%hh:%mm');
  XLSX.writeFile(wb, `${filename}.xlsx`, { bookSST: true });
};

