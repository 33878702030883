import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Directive, HostBinding, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Directive({
  standalone: true,
  selector: '[attachDeviceWidth]'
})
export class AttachDeviceWidthDirective implements OnDestroy {
  @HostBinding('class') currentScreenSize?: string;
  private readonly destroyed = new Subject<void>();

  displayNameMap = new Map([
    [Breakpoints.XSmall, 'v-xsmall'],
    [Breakpoints.Small, 'v-small'],
    [Breakpoints.Medium, 'v-medium'],
    [Breakpoints.Large, 'v-large'],
    [Breakpoints.XLarge, 'v-xlarge'],
  ]);

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
  ) {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ]).pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed),
      ).subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown';
            this.cdRef.markForCheck();
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

}
