export class SessionStorageUtil<T = unknown> {
  static set<T = unknown>(key: string, value: T, rewrite = false): void {
    if (SessionStorageUtil.has(key) && !rewrite) {
      throw new Error(`Data in storage key = ${key} aloready exists`);
    }

    sessionStorage.setItem(key, JSON.stringify(value));
  }

  static has(key: string): boolean {
    return typeof sessionStorage.getItem(key) === 'string';
  }

  static get<T = unknown>(key: string, optional = true): T | undefined {
    const value = sessionStorage.getItem(key);
    if (typeof value === 'string') {
      return JSON.parse(value);
    }

    if (!optional) {
      throw new Error(`Missing data in storage for key = ${key}`);
    }

    return undefined;
  }
}