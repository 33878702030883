import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { QueryUserDto, UserType } from '@core/entities/user';
import { SelectVirtualUserComponent } from '../ui/select-virtual-user/select-virtual-user.component';

export interface VirtualUserSelectOperation {
  status: 'selected' | 'rejected';
  user: string | undefined;
}

@Injectable({ providedIn: 'root' })
export class VirtualUserService {
  private dialog = inject(MatDialog);

  getUser(query?: QueryUserDto): Observable<VirtualUserSelectOperation> {
    const dialogRef = this.dialog.open(SelectVirtualUserComponent, { ...(query && { data: { ...query, type: UserType.virtual } }), width: '50vw' });

    return dialogRef.afterClosed().pipe(
      map((data: string) => {
        if (!data) {
          return { status: 'rejected', user: data };
        }

        return { status: 'selected', user: data }
      })
    );
  }
}
