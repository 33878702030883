import { Injectable } from '@angular/core';

import printJS from 'print-js';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiService } from '@core/services/api';

export interface PackingSlipOptions {
  orderId?: string;
  batchName?: string;
}

interface PackingSlipData {
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class PackingService {

  constructor(private apiService: ApiService) {
  }

  loadPackingSlip(options: PackingSlipOptions): Observable<string> {
    const url = '/orders/packing-slip';

    return this.apiService.get<PackingSlipData>(url, options).pipe(
      switchMap(downloadUrl => {
        return from(new Promise<string>((resolve, reject) => {
          const config: printJS.Configuration = {
            printable: downloadUrl.data.url,
            onLoadingEnd: () => resolve(downloadUrl.data.url),
            onError: err => reject(err)
          }

          printJS(config);
        }))
      }),
    );
  }
}
