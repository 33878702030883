import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { Asset, AssetType } from '../model/asset.model';
import { FileUtil } from '@core/utils';
import { EXTERNAL_HTTP_REQUEST } from '@core/auth/interceptors/api.interceptor';
import { SKIP_AUTH } from '@core/auth/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class FileAssetService {
  private http$ = inject(HttpClient);

  private get apiUrl() {
    return '/assets'
  }

  public uploadFile(file: File, type: AssetType, subType?: string): Observable<Asset> {
    return this.registerFileUpload(type, file, subType).pipe(
      switchMap((asset: Asset) => {
        return this.uploadAsset(asset.uploadUrl, file).pipe(
          map(() => asset),
        );
      }),
    );
  }

  private registerFileUpload(type: AssetType, file: File, subType?: string): Observable<Asset> {
    return this.http$.post<Asset>(this.apiUrl, {
      type,
      fileName: file.name,
      subType,
    });
  }

  private uploadAsset<T extends { url: string }>(uploadUrl: string, file: File): Observable<T> {
    const headers = new HttpHeaders({ 'Content-Type': FileUtil.getFileMetadata(file).mime });
  return this.http$.put<T>(uploadUrl, file, { headers, context: new HttpContext().set(EXTERNAL_HTTP_REQUEST, true).set(SKIP_AUTH, true) });
  }
}
