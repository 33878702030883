import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

const endpoint = '/production-alert';

interface ProductionAlertResponse {
  data: any[],
  total: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProductionAlertService {

  getDataByCustomFilterId(entity: string, filterId: string, dynamicFilter: { [key: string]: any }): Observable<ProductionAlertResponse> {
    const params = new HttpParams({ fromObject: dynamicFilter }).set('filterId', filterId);
    return this.http$.get<ProductionAlertResponse>(`/production-alert/${entity}`, { params });
  }

  constructor(
    private http$: HttpClient,
  ) { }
}
