import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';

import { Gallery, GalleryModule, ImageItem, } from 'ng-gallery';
import { Lightbox, LightboxModule } from 'ng-gallery/lightbox';

import { FileUtil, downloadImage } from '@core/utils';
import { DocumentFileExtension, ImageFileExtension } from '@core/entities/asset';
import { GenericButtonComponent } from '../common';
import { AttachmentData, AttachmentType, } from './attachment-view.model';

@Component({
  standalone: true,
  selector: 'kt-attachment-view',
  templateUrl: './attachment-view.component.html',
  styleUrls: ['./attachment-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    GalleryModule,
    GenericButtonComponent,
    LightboxModule,
    MatDividerModule,
    MatMenuModule,
    NgFor,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
  ],
})
export class AttachmentViewComponent {
  private gallery = inject(Gallery);
  private lightbox = inject(Lightbox);

  protected documents: AttachmentData[] = [];

  @Input() prefix = '';
  @Input({ required: true }) set urls(data: string[] | undefined) {
    this.initView(data);
  };

  @Output() remove = new EventEmitter<number>();

  private initView(urls: string[] = []): void {
    const documents: AttachmentData[] = [];
    const images: ImageItem[] = [];

    let imageIdx = 0;
    urls.forEach(url => {
      const type = this.getType(url);
      const fileIndex = String(documents.length + 1).padStart(2, '0');
      const fileName = (this.prefix ? `${this.prefix}-` : '') + 'delivery-attachment-' + fileIndex;

      const doc = {
        url,
        fileName,
        title: `file-${documents.length + 1}.${FileUtil.getFileExtension(url)}`,
        type,
        removable: true,
        previewable: type === AttachmentType.image ? true : false,
        downloadable: true,
        ...(type === AttachmentType.image && { imgIdx: imageIdx++ })
      };

      if (type === AttachmentType.image) {
        doc.imgIdx = imageIdx;
        imageIdx += 1;
        images.push(new ImageItem({ src: url, thumb: url }));
      }

      documents.push(doc);
    });

    this.documents = documents;
    this.gallery.ref('lightbox').load(images);
  }

  private getType(url: string): AttachmentType {
    const extension = FileUtil.getFileExtension(url);
    if (Object.values(ImageFileExtension).includes(extension as ImageFileExtension)) {
      return AttachmentType.image;
    }

    if (Object.values(DocumentFileExtension).includes(extension as DocumentFileExtension)) {
      return AttachmentType.document;
    }

    return AttachmentType.unknown;
  }

  downloadFile(data: AttachmentData): void {
    downloadImage(data.url, data.fileName)
  }

  openPreview(idx: number): void {
    this.lightbox.open(idx);
  }

  removeAttachment(idx: number): void {
    this.remove.emit(idx);
  }
}
