import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  ApiResponse,
  OrderAggregation,
  OrderAggregationFilter,
  OrderAggregationInclude,
  OrderDetailsAggregation,
  Page,
  ScanningLogAggregation,
} from '@core/models';
import { ApiUtil } from '@core/utils/api.utils';
import { NotificationService } from '@core/services/ui';

@Injectable({
  providedIn: 'root'
})
export class AggregationService {

  constructor(
    private http$: HttpClient,
    private notification: NotificationService,
  ) { }

  aggregateOrders(filter: Partial<OrderAggregationFilter> = {}, includes?: OrderAggregationInclude[]): Observable<ApiResponse<OrderAggregation[]>> {
    const url = `/aggregations/orders`;
    const params = ApiUtil.appendJoinFields(new HttpParams({ fromObject: filter }), includes as string[]);

    return this.http$.get<ApiResponse<OrderAggregation[]>>(url, { params }).pipe(
      catchError(error => this.handleError<OrderAggregation>(error)),
    );
  }

  aggregateOrder(orderId: string): Observable<OrderDetailsAggregation> {
    const url = `/aggregations/orders/${orderId}`;
    return this.http$.get<OrderDetailsAggregation>(url).pipe(
    );
  }

  aggregateScanningLog(filter: Page & { [key: string]: any }): Observable<ApiResponse<ScanningLogAggregation[]>> {
    const url = `/aggregations/scanning-log`;

    return this.http$.get<ApiResponse<ScanningLogAggregation[]>>(url, { params: filter }).pipe(
      catchError(error => this.handleError<ScanningLogAggregation>(error)),
    );
  }

  private handleError<T>(error: Error) {
    this.notification.showError(error.message);
    return of({} as ApiResponse<T[]>);
  }
}
