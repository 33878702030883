import { Injectable, } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private defaultConfig: MatSnackBarConfig = {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
  };

  constructor(
    private snackBar: MatSnackBar,
  ) { }

  private showNotification(message: string, action: string, config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, { ...this.defaultConfig, ...(config && config) });
  }

  showInfo(message: string, config?: MatSnackBarConfig) {
    return this.showNotification(message, 'info', config);
  }

  showError(message: string, config?: MatSnackBarConfig) {
    return this.showNotification(message, 'error', config);
  }
}
