import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiResponse, DateRange } from '@core/models';
import { ApiService } from './api/api.service';

export interface ActionLogFilter extends Partial<DateRange> {
  action?: string;
  actionType?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ActionlogService {

  constructor(private apiService: ApiService) {
  }

  getActionlogData(filter: { [key: string]: string | number }): Observable<ApiResponse<any>> {
    const url = '/actionlog';
    return this.apiService.get<any>(url, filter);
  }
}
