import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpContextToken,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '@core/reducers';
import { Logout } from '@core/auth';
import { ApiUtilsService } from '@coreservices/api/api-utils.service';

export const SKIP_AUTH = new HttpContextToken<boolean>(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private apiUtils: ApiUtilsService,
    private store: Store<AppState>,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.context.get(SKIP_AUTH) === true) {
      return next.handle(request);
    }

    const clonnedRequest = request.clone({ headers: this.apiUtils.addAuthHeaders(request.headers) });

    return next.handle(clonnedRequest).pipe(
      catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
    );
  }

  private handleErrorResponse(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      this.store.dispatch(new Logout());
    }

    return throwError(error);

  }
}
