// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Services
import { MenuConfigService } from './menu-config.service';
import { ObjectUtil } from '@coreutils';

@Injectable()
export class MenuHorizontalService {
  // Public properties
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  /*
	 * Service constructor
	 *
	 * @param menuConfigService: MenuConfigService
	 */
  constructor(private menuConfigService: MenuConfigService) {
    this.loadMenu();
  }

  /*
	 * Load menu list
	 */
  loadMenu() {
    // get menu list
    const menuItems: any[] = ObjectUtil.getValueByPath(this.menuConfigService.getMenus(), 'header.items');
    this.menuList$.next(menuItems);
  }
}
