import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  Report,
  ReportName,
  ReportResponse,
  TotalItemPerStageFilter,
  TotalOrdersByStatusWithSLA,
  TotalProductTypesPerOrderStatusFilter,
  TotalProductTypesPerOrderStatusReport,
} from '@core/reports';
import { TIMEZONE_REQUIRED } from '@coreauth/interceptors/api.interceptor';

export interface ItemsPerStage {
  stage: string;
  total: number;
}

export type ItemsPerStageReport = ItemsPerStage[];

@Injectable({ providedIn: 'root' })
export class ReportService {

  constructor(private http$: HttpClient) { }

  getReport(filter?: { [key: string]: any }): Observable<ReportResponse> {
    const url = `/reports`;
    const params = new HttpParams({ fromObject: filter });

    return this.http$.get<ReportResponse>(url, {
      params,
      context: new HttpContext().set(TIMEZONE_REQUIRED, true),
    });
  }

  getTotalOrdersPerStatusWithSLA(filter: any): Observable<Report<TotalOrdersByStatusWithSLA>> {
    const url = `/reports`;
    const params = new HttpParams({ fromObject: filter }).set('report', ReportName.TotalOrdersPerStatusWithSLA);

    return this.http$.get<Report<TotalOrdersByStatusWithSLA>>(url, { params });
  }

  getTotalItemsPerStage(filter: Partial<TotalItemPerStageFilter>): Observable<Report<ItemsPerStageReport>> {
    const url = `/reports`;
    const params = new HttpParams({ fromObject: filter }).set('report', ReportName.TotalItemsPerStage);

    return this.http$.get<Report<ItemsPerStageReport>>(url, { params });
  }

  getTotalProductTypesPerOrderStatus(filter: Partial<TotalProductTypesPerOrderStatusFilter>): Observable<Report<TotalProductTypesPerOrderStatusReport>> {
    const url = `/reports`;
    const params = new HttpParams({ fromObject: filter as any }).set('report', ReportName.TotalProductTypesPerOrderStatus);

    return this.http$.get<Report<TotalProductTypesPerOrderStatusReport>>(url, { params });
  }
}
