import pluralizer from 'pluralize';

export class StringUtil {

  static trim = (text: unknown) => typeof text === 'string' ? text.trim() : text;

  static pluralize = (text: string, qty: number = 1): string => pluralizer(text || '', qty);

  static capitalize = (text = '') => text.charAt(0).toUpperCase() + text.slice(1);

  static capitalizeAll(text = ''): string {
    if (!text) {
      text = '';
    }

    return text
      .split(/\s+/)
      .map(c => StringUtil.capitalize(c))
      .join(' ');
  }

  static getSimpleHash(input: string) {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = (hash * 31 + char) % 0xFFFFFFFF;
    }

    return hash;
  }
}
