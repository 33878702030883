import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { SKIP_AUTH } from '@core/auth/interceptors/auth.interceptor';
import { User } from '@core/entities/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static readonly endpoint = 'auth';
  private http$ = inject(HttpClient);

  login(username: string, password: string): Observable<{ user: User; token: string; }> {
    return this.http$.post<any>(`/${AuthService.endpoint}`, { username, password });
  }

  getUserByToken(): Observable<User> {
    return this.http$.get<User>(`/${AuthService.endpoint}`);
  }

  register(password: any, token: string): Observable<any> {

    if (!!token) {
      localStorage.setItem(environment.authTokenKey, token);
    }

    return this.http$.post(`/${AuthService.endpoint}/register`, { password })
      .pipe(
        catchError(() => EMPTY)
      );
  }

  isInvited(token: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token,
    });

    const context = new HttpContext().set(SKIP_AUTH, true)
    return this.http$.get<any>(`/${AuthService.endpoint}/invited`, { headers: httpHeaders, context });
  }

  requestPassword(email: string): Observable<any> {
    return this.http$.post(`/${AuthService.endpoint}/forgot`, { email });
  }

  requestResetPassword(password: string, token: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http$.post(`/${AuthService.endpoint}/resetp`, { password, token }, { headers: httpHeaders })
      .pipe(
        catchError(() => EMPTY)
      );
  }
}
