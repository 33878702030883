import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from './button';
import { FormControlModule } from './form-control';
import { FactoryAlertModule } from './factory-alert/factory-alert.module';
import { PackingSlipModule } from '@shared/packing-slip/packing-slip.module';
import { RushOrderSignModule } from './rush-order-sign/rush-order-sign.module';
import { PriorityComponent } from './priority/priority.component';
import { MermaidRendererComponent } from './mermaid-renderer/mermaid-renderer.component';
import { ImageComponent } from './image/image.component';
import { AttachmentViewComponent } from './attachment-view/attachment-view.component';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { GenericAvatarComponent } from './generic-avatar/generic-avatar.component';

const UIModules = [
  ButtonModule,
  FormControlModule,
  FactoryAlertModule,
  RushOrderSignModule,
  PackingSlipModule,
  PriorityComponent,
  MermaidRendererComponent,
  ImageComponent,
  AttachmentViewComponent,
  ImageSliderComponent,
  GenericAvatarComponent,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...UIModules,
  ],
  exports: [
    ...UIModules,
  ],
})
export class UiModule { }
