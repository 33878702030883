// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const ux = {
  DEBOUNCE_TIME: 275,
  USER_INPUT_DEBOUNCE_TIME: 275,
  USER_SELECT_DEBOUNCE_TIME: 550,
};

export const baseEnvironment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d6',
  CLOUDINARY: {
    DOMAIN: 'http://res.cloudinary.com/',
    IMAGE_PREVIEW_PATH:
      'http://res.cloudinary.com/doublero/image/upload/w_600/q_auto:low/',
  },
  authorization: 'ObpirxoNy9aosxTZxXEx',
  imageHostingAPIKey: '654ebbfcdc42cc8ce465',
  ux,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
