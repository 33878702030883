// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { ObjectUtil } from '@coreutils';

/*
 * Returns object from parent object
 */
@Pipe({
  name: 'getObject'
})
export class GetObjectPipe implements PipeTransform {
  /*
	 * Transform
	 *
	 * @param value: any
	 * @param args: any
	 */
  transform(value: any, args?: any): any {
    return ObjectUtil.getValueByPath(value, args);
  }
}
