import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RushOrderSignComponent } from './rush-order-sign.component';
import { MaterialModule } from '@shared/material.module';

@NgModule({
  declarations: [RushOrderSignComponent],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    RushOrderSignComponent,
  ]
})
export class RushOrderSignModule { }
