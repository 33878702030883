import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateUserDto, QueryUserDto, UpdateUserDto, User, UsersResponse } from '../model/user.model';
import { Page } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private http$ = inject(HttpClient);
  static readonly endpoint = 'users';

  createUser(data: CreateUserDto): Observable<User> {
    return this.http$.post<User>(`/${UserService.endpoint}`, data);
  }

  updateUser(userId: string, data: UpdateUserDto): Observable<User> {
    return this.http$.put<User>(`/${UserService.endpoint}/${userId}`, data);
  }

  deleteUser(userId: string): Observable<User> {
    return this.http$.delete<User>(`/${UserService.endpoint}/${userId}`);
  }

  getUser(userId: string): Observable<User> {
    return this.http$.get<User>(`/${UserService.endpoint}/${userId}`);
  }

  getUsers(query: Partial<QueryUserDto & Page>): Observable<UsersResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.http$.get<UsersResponse>(`/${UserService.endpoint}`, { params });
  }

  sendUserInvitation(userId: string): Observable<User> {
    return this.http$.post<User>(`/${UserService.endpoint}/${userId}/invite`, {});
  }
}
