import { StringUtil } from "./string.utils";

export class ColorUtil {
  static generateHexColor(text: string) {
    const hash = StringUtil.getSimpleHash(text);
    const r = hash % 256;
    const g = (hash >> 8) % 256;
    const b = (hash >> 16) % 256;

    // Convert R, G, B values to a hex color string
    const hexColor = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

    return hexColor;
  }

  static getColorLuma(hex: string) {

    const rgb = parseInt(hex.slice(1), 16);   // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff;  // extract red
    const g = (rgb >> 8) & 0xff;  // extract green
    const b = (rgb >> 0) & 0xff;  // extract blue

    return 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  }
}