import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';

import { Subject, take, takeUntil } from 'rxjs';

export type ButtonAppearance = 'flat' |
  'raised' |
  'stroked' |
  'basic' |
  'icon' |
  'fab' |
  'mini-fab' |
  'fab-extended' |
  'mat-menu-item' |
  'link';

export type ButtonColor = 'basic' | 'primary' | 'accent' | 'warn' | undefined;
export type ButtonMode = 'click' | 'confirmation';

@Component({
  standalone: true,
  selector: 'kt-generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: ['./generic-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
  ],
})
export class GenericButtonComponent implements OnDestroy {
  static readonly PALETTE_COLORS = ['basic', 'primary', 'accent', 'warn'];
  readonly LINK_COLOR = 'rgb(0, 0, 238)';

  @HostBinding('style.display') hostDisplayMode: 'inline-flex' | 'block' = 'inline-flex';

  @ViewChild('confirmationDialogTpl') confirmationDialogTpl?: TemplateRef<ElementRef>;

  protected themeColor: ButtonColor = 'basic';
  protected customColor?: string;
  protected btnAppearance: ButtonAppearance = 'flat';

  @Input() set appearance(appearance: ButtonAppearance) {
    if (appearance === 'mat-menu-item') {
      this.hostDisplayMode = 'block';
    }

    this.btnAppearance = appearance;
  };
  @Input() icon?: string;
  @Input() scale = 1;
  @Input() set color(color: ButtonColor | string) {
    if (color && GenericButtonComponent.PALETTE_COLORS.includes(color)) {
      this.themeColor = color as ButtonColor;
    }

    if (color && !GenericButtonComponent.PALETTE_COLORS.includes(color)) {
      this.customColor = color;
    }
  };
  @Input() tooltipMessage = '';
  @Input() tooltipDisabledMessage = '';
  @Input() tooltipPosition: TooltipPosition = 'above';
  @Input() matTooltip = '';
  @Input() padding = 0;
  @Input() disabled = false;
  @Input() hidden = false;
  @Input() loading: boolean | null = false;

  @Input() mode: ButtonMode = 'click'
  @Input() confirmationType: 'warning' | 'info' = 'warning';
  @Input() confirmationMessage = 'Confirm your action?';
  @Input() confirmationDescription?: string;

  @Output() confirm = new EventEmitter<void>();

  private readonly onDestroy$ = new Subject<void>();
  constructor(
    private dialog: MatDialog,
  ) { }

  protected click(): void {
    if (this.mode === 'confirmation') {
      this.openConfirmationDialog();
      return;
    }
  }

  protected proxyClick(event: MouseEvent): void {
    if (this.disabled) {
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
  }

  protected openConfirmationDialog(): void {
    if (this.confirmationDialogTpl) {
      this.dialog.open(this.confirmationDialogTpl).afterClosed().pipe(
        take(1),
        takeUntil(this.onDestroy$),
      ).subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.confirm.emit();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
